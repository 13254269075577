<template>
  <div class="submit" v-if="props.peddingOrder == 1">
    <button v-if="route.query.type == 2" @click="OnPedding" class="chatbtns">
      {{ lang.v.UpdataPedding }}
    </button>
  </div>
  <template v-else>
    <div
      class="submit"
      v-if="orderData.status != 6 && orderData.normal && orderData.status != 3"
    >
      <button @click="OnChat" class="chatbtn">
        {{ lang.v.CustomerService }}
      </button>
      <button
        v-if="orderData.status != 4 && orderData?.sellUser == userinfo?.userId"
        class="pedding"
      >
        {{ lang.v.WaitingPayment }}
      </button>
      <button
        v-else-if="
          orderData.status == 4 && orderData?.sellUser != userinfo?.userId
        "
        class="pedding"
      >
        {{ lang.v.Collectionpayment }}
      </button>
      <button
        v-else
        @click="onsubmitOrder"
        :class="[
          'submit-btn',
          orderData.status != 0 && orderData.status != 1 ? 'disabled' : '',
        ]"
      >
        {{ lang.v.confirmCompletion }}
      </button>
    </div>
    <div class="submit" v-else>
      <button @click="OnChat" class="chatbtns">
        {{ lang.v.ContactCustomer }}
      </button>
    </div>
  </template>
</template>

<script setup>
import { ref, watch, defineProps } from 'vue';
import message from '@/utils/message';
import { lang } from '@/lang';
import {
  fetchTransactionServePay,
  fetchTransactionServeConfirm,
  fetchIMChat,
} from '../../api/fetcher';

import { useRouter, useRoute } from 'vue-router';
// 获取用户信息
const userinfo = JSON.parse(localStorage.getItem('userInfo'));

const router = useRouter();
const route = useRoute();
const orderData = ref({});
const props = defineProps({
  required: Function,
  orderStatus: {
    type: Number,
  },
  orderData: {
    type: Object,
  },
  peddingOrder: {
    type: Number,
    default: 2,
  },
  version: {
    type: Number,
    default: 2,
  },
});
//watch 监听
watch(
  () => props?.orderData,
  newValue => {
    orderData.value = newValue;
  },
  { deep: true }
);
// 调启客服
const OnChat = async () => {
  const data = await fetchIMChat({
    orderId: props.orderData.id,
  });
  router.push({
    path: '/im',
    query: {
      domain: data,
      group: 'group',
    },
  });
};

//跳转修改挂单
const OnPedding = () => {
  router.push({
    path: '/pendingOrders',
    query: {
      type: props.orderData.type,
      id: props.orderData.id,
      version: props.orderData.version,
      edit: true,
    },
  });
};
// 确认订单函数
const onsubmitOrder = async () => {
  if (props.orderData.status == 1 || props.orderData.status == 0) {
    const data = await fetchTransactionServePay({
      orderId: props.orderData.id,
      version: props.orderData.version,
    });
    if (data) {
      message.success(lang.v.optSuccess);
      props.required();
    }
  } else if (props.orderData.status == 4) {
    const data = await fetchTransactionServeConfirm({
      orderId: props.orderData.id,
      version: props.orderData.version,
    });
    if (data) {
      message.success(lang.v.optSuccess);
      props.required();
    }
  }
};
</script>

<style lang="less" scoped>
.disabled {
  background: #888596;
}

.submit {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 0.6rem;
  background: #ffffff;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .submit-btn {
    flex: 1;
    margin: 0.07rem 0.14rem;
    height: 0.46rem;
    background: #ed931e;
    border-radius: 0.23rem;
    font-weight: 600;
    font-size: 0.16rem;
    color: #ffffff;
    line-height: 0.22rem;
  }
  .pedding {
    flex: 1;
    margin: 0.07rem 0.14rem;
    height: 0.46rem;
    background: #888596;
    border-radius: 0.23rem;
    font-weight: 600;
    font-size: 0.16rem;
    color: #ffffff;
    line-height: 0.22rem;
  }
  .activity {
    background: #aaaaaa;
  }
}
.chatbtn {
  padding: 0 0.3rem;
  margin: 0.07rem 0.14rem;
  height: 0.46rem;
  width: 1.4rem;
  border: 0.01rem solid #ed931e;
  border-radius: 0.23rem;
  font-weight: 600;
  font-size: 0.16rem;
  color: #ed931e;
  line-height: 0.22rem;
}
.chatbtns {
  padding: 0 0.3rem;
  margin: 0.07rem 0.14rem;
  height: 0.46rem;
  width: 100%;
  border: 0.01rem solid #ed931e;
  border-radius: 0.23rem;
  font-weight: 600;
  font-size: 0.16rem;
  color: #ed931e;
  line-height: 0.22rem;
}
</style>
